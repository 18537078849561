<i18n src='../locales.json'></i18n>

<template>
  <v-layout class="my-investments" row wrap>
    <v-flex class="account-details">
      <v-layout class="header" row wrap>
        <v-flex>
          <h3>{{ $t('L_account_details') }}</h3>
        </v-flex>
        <v-flex class="subtitle">
          {{ $t('L_account_snapshot') }}
        </v-flex>
        <v-flex xs12 class="mt-4 mb-4">
          <v-icon color="#02579e" size="20" class="mr-2">
            info
          </v-icon>
          <span class="mr-2">{{ $t('L_some_accounts_may_not_be_displayed') }}</span>
          <div v-if="$vuetify.breakpoint.xsOnly">
            &nbsp;
          </div>
          <span @click="openAvailableProducts()" class="blueBoldedFont" :class="$vuetify.breakpoint.xsOnly?'spacingLearnMoreXsOnly':''">{{ $t('L_learn_more2') }}</span>
        </v-flex>
        <v-flex xs12 class="mb-2" v-if="allowLinkMinorAccounts">
          <a href="javascript:void(0)" @click="navigateToLinkMinorsAccountPage">
            <v-icon @click="navigateToLinkMinorsAccountPage" size="20" color="#02579e" class="mr-2">
              add_link
            </v-icon> {{ $t('L_link_a_minors_acct') }}
          </a>
          <v-icon
            color="#02579e"
            class="cursor-pointer ml-1"
            size="20"
            @click.stop="showLinkMinorAcctTooltip=!showLinkMinorAcctTooltip"
            @blur="showLinkMinorAcctTooltip = !showLinkMinorAcctTooltip"
            @focus="showLinkMinorAcctTooltip = !showLinkMinorAcctTooltip"
          >
            info
          </v-icon>
          <v-tooltip
            v-model="showLinkMinorAcctTooltip"
            slot="append"
            content-class="text-xs-center"
            right
            :max-width="this.$vuetify.breakpoint.mdAndUp ? '194' : '162'"
          >
            <template v-slot:activator="{ on }">
              <v-icon small v-on="on" />
            </template>
            <span
              role="tooltip"
              aria-live="polite"
              id="minor_link_tooltip"
            >
              {{ $t('L_minor_account_tooltip') }}
            </span>
          </v-tooltip>
        </v-flex>
      </v-layout>

      <div xs12 v-for="(group, index) in investments.groups" :key="group.name">
        <ResponsiveTable
          class="responsive-table"
          :display-header="displayHeaders(index)"
          :headers="headers"
          :data="accounts(group.accounts, group.name)"
          @clicked-item="openDisclaimer"
          @linked-item="openAccountNumber"
          :mobile="false"
        />
        <div v-if="group.name">
          <v-layout v-if="isMobile" row wrap class="subfooter">
            <v-flex xs7 sm10 font-weight-bold text-xs-left>
              {{ $t('L_investment_account_subtotal', { name: group.name }) }}
            </v-flex>
            <v-flex xs5 sm2 font-weight-bold text-xs-right>
              {{ $n(group.total, 'currency', 'en-US') }}
            </v-flex>
          </v-layout>
          <v-layout v-else text-xs-right row wrap class="subfooter">
            <v-flex xs7 sm10 font-weight-bold>
              {{ $t('L_investment_account_subtotal', { name: group.name }) }}
            </v-flex>
            <v-flex xs5 sm2 font-weight-bold>
              {{ $n(group.total, 'currency', 'en-US') }}
            </v-flex>
          </v-layout>

          <v-layout v-if="isCanadian" row wrap text-xs-right class="subfooter">
            <v-flex xs7 sm10 body-1>
              {{ $t(investmentAccountName(group.offeredBy)) }}
            </v-flex>
            <v-flex xs5 sm2 />
          </v-layout>
        </div>
      </div>

      <!-- Total Value -->
      <v-layout justify-center class="footer" row wrap>
        <v-layout row wrap justify-center class="total" font-weight-bold pt-3 pb-3 pr-1>
          <v-spacer />
          <v-flex xs6 sm3 md2 lg2 label text-xs-left>
            {{ $t('L_total_value') }}
          </v-flex>
          <v-flex xs6 sm3 md2 lg2 text-xs-right>
            {{ $n(investments.summary.totalValue, 'currency', 'en-US') }}
          </v-flex>
        </v-layout>
      </v-layout>

      <!-- Custodial Accounts -->
      <v-layout column v-if="!isCanadian">
        <v-flex class="account px-3 mt-5 mb-2 font-weight-bold">
          <i aria-hidden="true" class="v-icon v-icon--link material-icons theme--light gray--text text--darken-2" style="font-size: 18px">bar_chart</i>
          &nbsp;<span class="group">{{ $t('L_linked_custodian_accounts') }}</span>
        </v-flex>
        <LinkedCustodianAccounts
          class="responsive-table"
          :display-header="false"
          :headers="headers"
          :data="custodians"
          @company-name-clicked="openDisclaimer"
          @account-number-clicked="openAccountNumber"
        />
      </v-layout>

      <!-- Footer -->
      <v-layout justify-center class="footer" row wrap>
        <div>
          <v-layout row wrap justify-center pt-4>
            <v-flex xs12 sm12 md12 lg12 body-1 text-xs-left class="disclosure">
              <!-- Balances may not be current or reflect all of your accounts opened through PFSL and PLICC.   -->
              <!-- For up to date and complete information, refer to your account statements and confirmations.  -->
              <!-- The information above must be read with the disclosures contained  -->
              <span v-if="isCanadian">{{ $t('L_important_disclosures_ca') }}
                <span class="disclosure_link" @click="openDisclosure()">
                  {{ $t('L_here') }}
                </span>
              </span>
              <div v-else>
                <p>
                  <!-- The information contained on this website is designed solely for your individual use and is for information purposes only. -->
                  <!-- For the most accurate information about your account(s), please review your official account statements and confirmations. See -->
                  <span>{{ $t('L_important_disclosures_us') }}
                    <span class="disclosure_link" @click="openDisclosure()">
                      {{ $t('L_important_disclosures') }}
                    </span>
                  </span>
                </p>
                <p v-if="summary.totalValue > 0">
                  <span>{{ $t('L_for_company_contact_information') }}</span>
                  <span v-if="!isCanadian"><a @click="helpAction">{{ $t('L_click_here') }} </a></span>
                </p>
              </div>
            </v-flex>
            <v-spacer />
          </v-layout>
        </div>
        <Statements v-if="isCanadian && hasInvestments" />
      </v-layout>
      <v-layout>
        <v-dialog v-model="dialog"
                  max-width="1000"
        >
          <v-card style="padding: 30px">
            <v-card-title class="text-xs-center">
              <v-flex xs12 font-weight-regular style="font-size: 28px">
                {{ $t('L_bny_disclaimer_title') }}
              </v-flex>
            </v-card-title>
            <v-card-text class="font-weight-regular" style="padding-bottom: 50px">
              <div class="font-weight-medium">
                {{ $t('L_bny_disclaimer_label') }}
              </div>
              <div>{{ $t('L_bny_disclaimer') }}</div>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-layout justify-center>
                <v-flex xs12 lg4 style="padding-right: 24px">
                  <v-btn block @click="dialog = false">
                    {{ $t('L_bny_disclaimer_cancel') }}
                  </v-btn>
                </v-flex>
                <v-flex xs12 lg4>
                  <v-btn color="primary" block @click="manageAccount">
                    {{ $t('L_bny_disclaimer_agree') }}
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-flex>
    <EmbeddedViewer
      :title="document.title"
      :loading="document.loading"
      :content-type="document.type"
      :content-url="document.content"
      @close="document.content = null"
    />
    <Representative
      v-if="hasAgents"
      :agents="agents"
    />
    <MessageDialog
      :show-dialog="dialogNumber.display"
      :has-button-two="false"
      :is-persistent="true"
      :type="dialogNumber.type"
      :title="$t('L_account_number')"
      :message="dialogNumber.message"
      message-align="center"
      :button-one-label="$t('L_ok')"
      @buttonOneAction="dialogNumber=false"
    />
  </v-layout>
</template>

<script>
import { COUNTRIES, FEATURE_FLAGS } from '@/shared/constants';
import { currency, percentage } from '@/mixins/numbers';
import MessageDialog from '@/components/MessageDialog.vue';
import ResponsiveTable from '@/components/ResponsiveTable/ResponsiveTable.vue';
import Config from '@/shared/util/config';
import Representative from '@/components/Representative.vue';
import { mapActions, mapGetters } from 'vuex';
import EmbeddedViewer from '@/components/EmbeddedViewer.vue';
import bff from '@/shared/api/bff';
import { openInNewWindow } from '@/mixins/openNewWindow';
import Statements from './Statements.vue';
import LinkedCustodianAccounts from './LinkedCustodianAccounts.vue';

export default {
  name: 'MyInvestments',
  mixins: [
    currency,
    percentage,
    openInNewWindow,
  ],
  data() {
    return {
      document: {
        title: '',
        content: '',
        type: '',
      },
      dialog: false,
      account: {},
      ssoRaUrl: '',
      bffSamlTokenVal: '',
      persistent: true,
      dialogNumber: {
        display: false,
        type: 'info',
        message: '',
      },
      showLinkMinorAcctTooltip: false,
    };
  },
  components: {
    Statements,
    Representative,
    ResponsiveTable,
    EmbeddedViewer,
    bff,
    MessageDialog,
    LinkedCustodianAccounts,
  },
  computed: {
    ...mapGetters([
      'containsFeature',
      'features',
    ]),
    ...mapGetters('customer', [
      'customerProfile',
      'investments',
      'loading',
      'investmentsSortedByDate',
    ]),
    ...mapGetters({
      locale: 'getLocale',
    }),
    ...mapGetters(['agents', 'getAgent']),
    isNotProd() {
      return Config.get('env') !== 'prod';
    },
    getBffSamlTokenVal() {
      return this.bffSamlTokenVal;
    },
    hasInvestments() {
      return this.investments.summary.totalValue > 0;
    },
    hasStatements() {
      return this.investments.statements.length;
    },
    isCanadian() {
      return this.customerProfile.country === COUNTRIES.CANADA;
    },
    hasAgents() {
      return this.agents?.length > 0;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
    agents() {
      const reps = [];
      if (this.investmentsSortedByDate) {
        let index = 0;
        this.investmentsSortedByDate.forEach((acct) => {
          const agent = Object.assign({}, this.getAgent(acct.agentId));
          if (agent.agentId) {
            agent.account = `${acct.productType} ${acct.accountNumber}`;
            agent.index = index;
            reps.push(agent);
            index += 1;
          }
        });
      }
      return reps;
    },
    summary() {
      return this.investments.summary;
    },
    custodians() {
      return this.investments?.custodians;
    },
    headers() {
      if (this.isCanadian) {
        return [
          {
            text: this.$t('L_account'),
            value: 'accountNumber',
          },
          {
            text: '',
            value: '',
          },
          {
            text: this.$t('L_account_ending_value'),
            value: 'endingValue',
          },
        ];
      } else if (this.isMobile) {
        return [
          {
            text: this.$t('L_account'),
            value: 'accountNumber',
          },
          {
            text: '',
            value: 'companyName',
          },
          {
            text: this.$t('L_account_ending_value'),
            value: 'endingValue',
          },
        ];
      }
      return [
        {
          text: this.$t('L_account'),
          value: 'accountNumber',
        },
        {
          text: this.$t('L_account_company'),
          value: 'companyName',
        },
        {
          text: this.$t('L_account_ending_value'),
          value: 'endingValue',
        },
      ];
    },
    helpFlag() {
      return this.containsFeature('HELP');
    },
    allowLinkMinorAccounts() {
      return this.containsFeature(FEATURE_FLAGS.INVESTMENT_CUSTODIAN);
    },
    allowViewAccountNumber() {
      return this.containsFeature(FEATURE_FLAGS.VIEW_ACCOUNT_NUMBER);
    },
  },
  methods: {
    ...mapActions('customer', [
      'getAccountNumber',
    ]),
    async navigateToLinkMinorsAccountPage() {
      // track in cp activity log
      await this.$store.dispatch('postAuditLog', { actionId: 'MinorAccountLink_StartAction' });
      this.$router.push({ name: 'MinorAccountLinkProcess' });
    },
    timeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async openAvailableProducts() {
      let respUrl = '';
      if (this.locale === 'fr_CA') {
        respUrl = `${Config.get('investments.available_products.fr_CA')}`;
      } else if (this.locale === 'en_US') {
        respUrl = `${Config.get('investments.available_products.en_US')}`;
      } else if (this.locale === 'en_CA') {
        respUrl = `${Config.get('investments.available_products.en_CA')}`;
      }
      await this.openInNewWindow({ url: respUrl, target: '_blank' });
    },
    async helpAction() {
      const embedUrl = 'https://assets.contentstack.io/v3/assets/blt1dedeb682e8df2a2/blt968817b7969bbd3b/RightAnswers_Investment_US_Custodian_Contact_List.pdf';
      await this.openInNewWindow({ url: embedUrl, target: 'RADashboardWinName' });
    },
    investmentAccountName(offeredBy) {
      return `L_investment_account_subtotal_${offeredBy}`;
    },
    displayHeaders(index) {
      if (index === 0) {
        return true;
      }
      return false;
    },
    accounts(arrs, groupName) {
      const eyeIcon = '<i id="link" aria-hidden="true" class="v-icon v-icon--link material-icons theme--light blue--text text--darken-2" style="font-size: 18px">visibility</i>';
      const launchIcon = '<i id="click" aria-hidden="true" class="v-icon v-icon--link material-icons theme--light blue--text text--darken-2" style="font-size: 18px">launch</i>';
      const graphIcon = '<i aria-hidden="true" class="v-icon v-icon--link material-icons theme--light gray--text text--darken-2" style="font-size: 18px">bar_chart</i>';
      const accounts = [];
      if (groupName && !this.isCanadian) {
        const account = {
          accountNumber: groupName,
          isGroup: true,
        };
        accounts.push(account);
      }
      arrs.forEach((arr) => {
        accounts.push(arr);
      });
      let index = 0;
      return accounts.map((accountDetails) => {
        const account = {};
        index += 1;
        Object.keys(accountDetails).forEach((prop) => {
          if (prop === 'accountNumber') {
            if (accountDetails.isGroup) {
              let markup = '<div class="account">';
              markup += `${graphIcon}&nbsp;<span class="group">${accountDetails.accountNumber}</span></div>`;
              account[prop] = {
                markup,
                clickable: false,
              };
              account.background = 'white';
            } else {
              let markup = '<div class="account"><div class="link">';
              if (this.isCanadian) {
                markup += `<span class="name">${accountDetails.productType}</span>`;
                if (index % 2 === 0) { // even
                  account.background = 'white';
                } else {
                  account.background = 'primarylight2';
                }
              } else if (!this.isCanadian) {
                if (index % 2 === 0) { // even
                  account.background = 'primarylight2';
                } else {
                  account.background = 'white';
                }
              }
              const linkable = this.allowViewAccountNumber;
              markup += `${accountDetails.accountNumber} `;
              markup += this.allowViewAccountNumber ? `${eyeIcon}</div>` : '</div>';

              markup += `<div class="type">${accountDetails.accountType}</div>`;
              if (this.isMobile) {
                markup += `<div class="link${accountDetails.isBNY ? ' bny' : ''}">`;
                markup += `<div id="click" class="type">${accountDetails.companyName}</div>`;
                markup += accountDetails.isBNY ? `${launchIcon}</div>` : '</div>';
                account[prop] = {
                  markup,
                  clickable: accountDetails.isBNY,
                  linkable,
                  id: accountDetails.id,
                };
              } else {
                account[prop] = {
                  markup,
                  clickable: false,
                  linkable,
                  id: accountDetails.id,
                };
              }
            }
          } else if (prop === 'companyName') {
            if (!this.isMobile) {
              let markup = '<div class="account">';
              markup += `<div class="link${accountDetails.isBNY ? ' bny' : ''}">`;
              markup += `<span id="click" class="type">${accountDetails.companyName}</span>`;
              markup += accountDetails.isBNY ? `${launchIcon}</div>` : '</div>';
              markup += '</div>';
              account[prop] = {
                markup,
                clickable: accountDetails.isBNY,
              };
            } else {
              account[prop] = { text: ' ', class: '' };
            }
          } else if (prop === 'endingValue') {
            let markup = `<div class="${'ending'}">`;
            const asOf = this.$t('L_as_of');
            markup += `<div class="value">${this.currency(accountDetails[prop])}</div>`;
            markup += `<div class="date">${asOf} ${accountDetails.asOfDate}</div></div>`;
            account[prop] = { markup };
          } else {
            let text;
            let klass;
            if (prop === 'inceptionROR') {
              klass = 'ror';
              text = this.percentage(accountDetails[prop]);
            } else {
              klass = accountDetails[prop] < 0 ? 'negative' : '';
              text = typeof accountDetails[prop] === 'number' ? this.currency(accountDetails[prop]) : accountDetails[prop];
            }
            account[prop] = { text, class: klass };
          }
        });
        return account;
      });
    },
    openDisclaimer(account) {
      this.account = account;
      this.dialog = true;
    },
    async openAccountNumber(account) {
      const accountNumber = await this.getAccountNumber(account.accountNumber.id);
      if (accountNumber) {
        this.dialogNumber = {
          type: 'info',
          message: accountNumber,
          display: true,
        };
      } else {
        this.dialogNumber = {
          type: 'error',
          message: this.$t('L_view_number_error'),
          display: true,
        };
      }
    },
    async manageAccount() {
      if (this.account.accountNumber) {
        this.account = {};
        this.dialog = false;
        await this.openInNewWindow({ url: Config.get('investments.bnyLogin'), target: '_blank' });
      }
    },
    openDisclosure() {
      this.document.type = 'application/pdf';
      this.document.title = this.$t('L_important_disclosures');
      if (this.customerProfile.country.toLowerCase() === 'ca') {
        this.document.content =
          `${Config.get('content_stack')}${Config.get('investments.important_disclosures.ca')}`;
      } else {
        this.document.content =
          `${Config.get('content_stack')}${Config.get('investments.important_disclosures.us')}`;
      }
    },
  },
};
</script>

<style lang="stylus">
@require '~vuetify/src/stylus/settings/_variables'
.spacingLearnMoreXsOnly
  padding-left: 32px;
.blueBoldedFont
  color: #02579e;
  font-weight: bold;
  cursor: pointer;
.buttonFormat
  width: 200px;
  height: 44px;
  font-size: 18px;
  font-weight: 600;
.buttonFormat_mobile
  width: 180px;
  height: 44px;
  font-size: 14px;
  font-weight: 600;
  margin-left: 0px !important;
span.number
  cursor: pointer;
.my-investments
  .total
    background-color: #e2f0fe;
  .disclaimer_heading
    font-size: 20px;
  .disclosure_link
    font-weight: 600;
    color: #02579e;
    cursor: pointer;
  .disclosure
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 10px;
  .account-details
    background-color: #fff;
  .header, .title
    padding: 15px;
  .header
    border-bottom: 1px solid #ccc;
    h3
      font-size: 24px;
      font-weight: 400;
    .subtitle
      color: #4a4a4a;
      padding: 0px;
      font-size: 20px;
  .responsive-table
    padding: 5px 15px;
    .v-table, tr
      border-bottom: none !important;
    td, th
      color: #333;
      font-size: 14px;
      text-align: right !important;
    thead
      th
        &:nth-child(1)
          text-align: left !important;
          width: 400px;
        &:nth-child(2)
          text-align: left !important;
        span
          color: #333;
          font-weight: 600;
        .v-icon
          display: none !important;
    tbody
      td
        padding-bottom: 5px !important;
        &:nth-child(1)
          text-align: left !important;
          width: 400px;
        &:nth-child(2)
          text-align: left !important;
        .negative
          &:after
            content: ')'
          &:before
            content: '('
        .account
          .link
            font-weight: 600;
            width: 100;
            &.bny
              color: #02579e;
              cursor: pointer;
          .number
            color: #02579e;
            margin: 0 0 0 5px;
          .group
            color: black;
            font-weight: 600;
        .date
          font-size: 12px;
    .v-card
      box-shadow: none;
  .footer
    padding: 15px 20px 10px;
    &.statements
      padding: 15px 0 0;
      .v-expansion-panel__container
        margin: 0;
    .label
      padding: 0 5px 0 0;
      font-weight: 600;
      padding-left: 42px;
      @media $display-breakpoints.md-and-up
        text-align: right;
    .value
      text-align: left;
      @media $display-breakpoints.md-and-up
        padding-left: 20px;
  .subfooter
    padding: 0px 20px 0px;
  .representative
    padding: 7px 0 0;
    .title
      padding: 0;
</style>
