<i18n src='../locales.json'></i18n>

<template>
  <v-data-table
    :aria-label="$t('L_policy_coverage_details')"
    :items="data"
    hide-actions
    hide-headers
    class="elevation-0 white"
  >
    <template
      v-slot:items="{ item, index }"
    >
      <tr :class="index % 2 === 0 ? 'primarylight2' : 'white'">
        <td
          v-for="(header, headerIndex) in headers"
          :key="headerIndex+'insured'"
          class="pl-2 pt-2 valign-top"
          :class="item[header.value] ? item[header.value].itemClass : ''"
        >
          <div
            :class="[{'clickable': item[header.value].clickable }, item[header.value].class]"
          >
            <!-- ACCOUNT NUMBER -->
            <div
              v-if="header.value==='accountNumber'"
              class="account"
            >
              <div class="link">
                {{ item.accountNumber }}
                <i
                  @click="accountNumberClicked(item)"
                  v-if="allowViewAccountNumber"
                  id="link"
                  aria-hidden="true"
                  class="v-icon v-icon--link material-icons theme--light blue--text text--darken-2"
                  style="font-size: 18px"
                >
                  visibility
                </i>
              </div>
              <div class="type">
                {{ item.accountType }}
              </div>
              <div>
                {{ item.firstName + ' ' + item.lastName }}
              </div>
              <template v-if="isMobile">
                <div
                  @click="companyNameClicked(item)"
                  :class="[{ 'linkbny': item.isBNY, 'link': !item.isBNY }]"
                >
                  <div id="click" class="type">
                    {{ item.companyName }}
                  </div>
                  <i
                    v-if="item.isBNY"
                    id="click"
                    aria-hidden="true"
                    class="v-icon v-icon--link material-icons theme--light blue--text text--darken-2"
                    style="font-size: 18px"
                  >
                    launch
                  </i>
                </div>
              </template>
            </div>
            <!-- COMPANY NAME -->
            <div
              @click="companyNameClicked(item)"
              v-else-if="header.value==='companyName'"
              class="account"
            >
              <div v-if="!isMobile" :class="[{ 'linkbny': item.isBNY, 'link': !item.isBNY }]">
                <span id="click" class="type">{{ item.companyName }}</span>
                <i
                  v-if="item.isBNY"
                  id="click"
                  aria-hidden="true"
                  class="v-icon v-icon--link material-icons theme--light blue--text text--darken-2"
                  style="font-size: 18px"
                >
                  launch
                </i>
              </div>
            </div>
            <!-- ENDING VALUE -->
            <div v-else-if="header.value==='endingValue'" class="ending">
              <div class="font-weight-medium">
                {{ currency(item.endingValue) }}
              </div>
              <div class="date">
                {{ $t('L_as_of') }} {{ item.asOfDate }}
              </div>
            </div>
          </div>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import { FEATURE_FLAGS } from '@/shared/constants';
import { currency } from '@/mixins/numbers';
import { mapGetters } from 'vuex';

export default {
  name: 'LinkedCustodianAccounts',
  mixins: [
    currency,
  ],
  props: {
    displayHeader: {
      type: Boolean,
      required: false,
      default: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'containsFeature',
    ]),
    ...mapGetters('policy', {
      isPending: 'isPending',
    }),
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
    allowViewAccountNumber() {
      return this.containsFeature(FEATURE_FLAGS.VIEW_ACCOUNT_NUMBER);
    },
  },
  methods: {
    clicked(e) {
      const targetId = e.target.id;
      if (targetId === 'click') {
        this.$emit('click');
      } else if (targetId === 'link') {
        this.$emit('link');
      }
    },
    accountNumberClicked(acct) {
      const a = { accountNumber: { id: acct.id } };
      this.$emit('account-number-clicked', a);
    },
    companyNameClicked(acct) {
      this.$emit('company-name-clicked', acct);
    },
    getHeaders() {
      const th = [].slice.call(this.$el.querySelectorAll('th.column-header'));
      this.adaTable(th);
    },
    adaTable(th) {
      th.forEach((item) => {
        item.removeAttribute('aria-label');
      });
    },
  },
  mounted() {
    this.getHeaders();
  },
};
</script>

<style lang="stylus" scoped>

@require '~vuetify/src/stylus/settings/_variables'
/deep/ th.column-header {
  vertical-align: top;
  padding-top: 9px !important;
}

/deep/ .valign-top {
  vertical-align: top;
}

.data-row
  padding-top: $spacers.one.y;
  padding-bottom: $spacers.one.y;
  @media $display-breakpoints.sm-and-up
    padding-top: $spacers.two.y;
    padding-bottom: $spacers.two.y;
    &:not(:first-child)
      border-top: solid 1px #cccccc;
    > :first-child
      padding-left: 42px;
</style>
